import React from 'react';
import { Link } from 'react-router-dom';

import Icons from '../Icons'
import Clients from './Clients';

function Testimonials() {
    return (
        <>
            <section className="testimonials-section section py-5 mx-auto">
                <div className="container">
                    <h3 className="mb-4 text-center section-heading">Clients I Worked With</h3>
                    <Clients/>

                    <div className="slider-container text-center px-md-4 px-lg-5">
                        <div className="testimonials-slider tiny-slider">
                            <div className='item'>
                                <div className='item-inner position-relative'>
                                    <div className='quote-holder'>
                                        
                                        <Icons icon={{ class: "fas fa-quote-left" }} className="quote-icon" />
                                        <Icons icon={{ class: "fas fa-quote-right" }} className="quote-icon" />
                                        <blockquote className='quote-content px-3 px-lg-5 mb-3 text-start'>
                                            You can list client testimonials here. Lorem ipsum dolor sit amet,
                                            consectetur adipiscing elit. Donec at erat vel quam cursus sagittis.
                                            <span className='quote-highlight'>Highlighted text example etiam volutpat
                                                ornare quam</span> at cursus mi tristique sit amet.
                                        </blockquote>
                                    </div>

                                    <div className='source-holder ps-3 ps-lg-5 text-start'>
                                        <div className='source-meta'>
                                            <div className='name'>Sam Doe, Lead Developer at Stripe</div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="item">
                                <div className="item-inner">
                                    <div className="quote-holder">
                                        {/* <i className="quote-icon fa-solid fa-quote-left"></i>
                                    <i className="quote-icon fa-solid fa-quote-right"></i> */}
                                        {/* <FontAwesomeIcon icon={faQuoteLeft} className='quote-icon' />
                                    <FontAwesomeIcon icon={faQuoteRight} className='quote-icon' /> */}
                                        <Icons icon={{ class: "fas fa-quote-left" }} className="quote-icon" />
                                        <Icons icon={{ class: "fas fa-quote-right" }} className="quote-icon" />
                                        <blockquote className="quote-content px-3 px-lg-5 mb-3 text-start">
                                            {/* <i className="quote-icon fa-solid fa-quote-left"></i> */}
                                            {/* <FontAwesomeIcon icon={faQuoteLeft} className='quote-icon' /> */}
                                            <Icons icon={{ class: "fas fa-quote-left" }} className="quote-icon" />
                                            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec at erat
                                            vel quam cursus sagittis. <span className="quote-highlight">Lorem ipsum
                                                dolor sit amet, consectetur adipiscing elit</span>. In egestas est
                                            at lorem lacinia malesuada vel at arcu.
                                        </blockquote>
                                    </div>

                                    <div className="source-holder ps-3 ps-lg-5 text-start">
                                        <div className="source-meta">
                                            <div className="name">Sarah Doe, Project Manager at Revlout</div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="item">
                                <div className="item-inner">
                                    <div className="quote-holder text-start text-md-center">
                                        {/* <i className="quote-icon fa-solid fa-quote-left"></i>
                                    <i className="quote-icon fa-solid fa-quote-right"></i> */}
                                        {/* <FontAwesomeIcon icon={faQuoteLeft} className='quote-icon' />
                                    <FontAwesomeIcon icon={faQuoteRight} className='quote-icon' /> */}
                                        <Icons icon={{ class: "fas fa-quote-left" }} className="quote-icon" />
                                        <Icons icon={{ class: "fas fa-quote-right" }} className="quote-icon" />
                                        <blockquote className="quote-content px-3 px-lg-5 mb-3 text-start">
                                            {/* <FontAwesomeIcon icon={faQuoteLeft} className='quote-icon' /> */}
                                            <Icons icon={{ class: "fas fa-quote-left" }} className="quote-icon" />
                                            {/* <Icons icon = {{class : "fab fa-quote-right"}} className="quote-icon" /> */}
                                            {/* <i className="quote-icon fa-solid fa-quote-left"></i> */}
                                            Excellent lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec
                                            at erat vel quam cursus sagittis. Curabitur vestibulum mollis leo, sed
                                            ultricies felis egestas ac. <span className="quote-highlight">Etiam volutpat
                                                ornare quam</span>.
                                        </blockquote>
                                    </div>

                                    <div className="source-holder ps-3 ps-lg-5 text-start">
                                        <div className="source-meta">
                                            <div className="name">James Doe, Tech Lead at Coinbase</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="slider-custom-controls d-none d-md-block">
                            <div className="prev" data-controls="prev">
                                {/* <FontAwesomeIcon icon={faChevronLeft} /> */}
                                <Icons icon={{ class: "fas fa-chevron-left" }} />
                            </div>

                            <div className="next" data-controls="next">
                                {/* <FontAwesomeIcon icon={faChevronRight} /> */}
                                <Icons icon={{ class: "fas fa-chevron-right" }} />
                            </div>
                        </div>
                    </div>

                    <div className="section-cta text-center mt-5">
                        <Link className="btn btn-primary theme-btn-cta" to="/contact">Get In Touch
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" className="bi bi-arrow-right-short" viewBox="0 0 16 16">
                                <path fillRule="evenodd"
                                    d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8z">
                                </path>
                            </svg>
                        </Link>
                    </div>
                </div>
            </section>
        </>
    );
}

export default Testimonials;