import React from 'react';
import { Link } from 'react-router-dom';

function Backlinks(props) {
    //console.log(props);
    return (
        <>
            <div className="section-cta text-center mt-4">
                <Link className="btn btn-primary theme-btn-cta" to={props.backlinks.url}>{props.backlinks.title}
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" className="bi bi-arrow-right-short" viewBox="0 0 16 16">
                        <path fillRule="evenodd"
                            d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8z">
                        </path>
                    </svg>
                </Link>
            </div>
        </>
    );
}

export default Backlinks;
