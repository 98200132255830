import React, { useEffect } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
//import { Link } from 'react-router-dom';

//import Loader from '../components/Loader';
//import feedbacks from '../data/feedbacks'

//import Icons from './../components/Icons';

import PageIntro from '../components/PageIntro';
import Testimonials from '../components/about/Testimonials';
import Skills from '../components/about/Skills';
import FeaturedProjects from '../components/about/FeaturedProjects';

function About() {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    //console.log(feedbacks);
    const pageIntro = {
        page: 'about',
        img: '/images/profile.png',
        title: 'Web Artisan',
        titleClass: 'text-uppercase',

        description: `Hi! I’m a Senior Developer with more than ten years of experience working in mobile
        banking apps. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Welcome to my
        online portfolio! Check out my`,

        descriptionUrlText: 'latest projects and case studies',
        descriptionUrl: '/projects',
    }

    const backlinksResume = { title: 'View Resume', url: '/resume', }
    //const backlinksProject = { title: 'More Projects', url: '/projects', }
    //const backlinksContact = { title: 'Get In Touch', url: '/contact', }

    return <>
        <HelmetProvider>
            <div>
                <Helmet>
                    <title>About :: Web Artisan</title>
                    <meta name="description" content="about" />
                </Helmet>
            </div>
        </HelmetProvider>

        <PageIntro pageIntro={pageIntro} backlinks={backlinksResume} />

        <FeaturedProjects />

        <Testimonials />

        <Skills />
    </>;
}

export default About;
