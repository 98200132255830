import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { Helmet, HelmetProvider } from 'react-helmet-async';

import About from './pages/About';
import Blogs from './pages/Blogs';
import BlogPost from './pages/BlogPost';
import Projects from './pages/Projects';
import ProjectDetails from './pages/ProjectDetails';
import Resume from './pages/Resume';
import Contact from './pages/Contact';
import SidePanel from './components/SidePanel';
import Topbar from './components/Topbar';
import Footer from './components/Footer';
// import Loader from './components/Loader';

// import { useState, useRef, useEffect } from 'react'
// import { keepTheme } from './utils/theme';

function App() {
  // useEffect(() => {
  //   window.scrollTo(0, 0);
  // }, []);

  // useEffect(() => {
  //   keepTheme();
  // })

  // const [loading, setLoading] = useState(true);
  // useEffect(() => {
  //   setTimeout(() => {
  //     setLoading(false)
  //   }, 18000);
  // }, []);

  return (
    <>
      <Router>
        <HelmetProvider>
          <div>
            <Helmet>
              <title>Home :: Web Artisan</title>
              <meta
                name="description"
                content="web artisan"
              />
              <meta
                name="keywords"
                content="web development, Audio, Lyrics"
              />
            </Helmet>
          </div>
        </HelmetProvider>

        <div className="container-fluid">
          <SidePanel />
          
          <div className="main-content-wrapper">
            <div className="container-fluid">
              <Topbar />
             
              <Routes>
                <Route path="/" element={<About title={"About"} />} />
                <Route path="/about" element={<About title={"About"} />} />
                <Route path="/blogs" element={<Blogs title={"Blogs"} />} />
                <Route path="/blog-post" element={<BlogPost title={"Blog Details"} />} />
                <Route path="/projects" element={<Projects title={"Projects"} />} />
                <Route path="/project-details" element={<ProjectDetails title={"Project Details"} />} />
                <Route path="/resume" element={<Resume title={"Resume"} />} />
                <Route path="/contact" element={<Contact title={"Contact"} />} />
              </Routes>
            </div>
          </div>
        </div>
      </Router>

      <Footer />
    </>
  );
}

export default App;
