//import React from 'react';
import { Link } from 'react-router-dom';

import React, { useEffect } from 'react';
import Cookies from 'js-cookie';
import SocialsLink from './SocialsLink';

function Topbar() {

  useEffect(() => {
    const modeToggler = document.getElementById('darkmode');
    const documentBody = document.getElementsByTagName('body')[0];

    if (typeof Cookies.get('mode') !== "undefined") {
      documentBody.classList.add('light-mode');
      modeToggler.checked = true; // toggle change

      //console.log('Cookie: light mode');

    } else {
      documentBody.classList.remove('light-mode');
      modeToggler.checked = false; // toggle change

      //console.log('Cookie: dark mode (default)');
    }

  }, []);

  const toggleMode = () => {
    const modeToggler = document.getElementById('darkmode');
    const documentBody = document.getElementsByTagName('body')[0];
    if (modeToggler.checked) {
      documentBody.classList.add('light-mode');
      //Set cookies for 7 days 
      Cookies.set('mode', 'light-mode', { expires: 7 });

      //console.log('change to light mode');

    } else {
      documentBody.classList.remove('light-mode');
      //Remove cookies
      Cookies.remove('mode');

      //console.log('change to default dark mode');
    }
  };

  return (
    <>
      <div className="top-bar text-center position-relative">
        <div className="top-bar-inner">
          <a className="menu-toggler d-none d-lg-block" data-bs-toggle="offcanvas" href="#offcanvasNavbar" role="button"
            aria-controls="offcanvasNavbar">
            <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor"
              className="bi bi-list" viewBox="0 0 16 16">
              <path fillRule="evenodd"
                d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z">
              </path>
            </svg>
          </a>

          <div className="mode-toggle text-center">
            <input className="toggle" id="darkmode" type="checkbox" onChange={toggleMode} />
            <label className="toggle-btn mx-auto mb-0" htmlFor="darkmode">
              <span className="day-icon toggle-icon"> <i className="bi bi-sun-fill"></i></span>
              <span className="night-icon toggle-icon"><i className="bi bi-moon-fill"></i></span>
            </label>
          </div>

          <Link className="btn btn-primary top-bar-cta" to="/contact">Hire Me</Link>
          <ul className="social-list list-inline mx-auto d-none d-lg-block">
            <SocialsLink />
          </ul>
        </div>
      </div>
    </>
  );
}

export default Topbar;
