import React from 'react';
import { Link } from 'react-router-dom';

function Skills() {
    return (
        <>
            <section className="skills-section section mx-auto mb-0">
                <h3 className="section-heading mb-4">Skills &amp; Experiences</h3>
                <div className="section-intro mb-5 limit-max-width text-md-center">
                    Section intro goes here. Lorem ipsum dolor sit amet, consectetur adipiscing elit. You can
                    replace or add more tech icons below to reflect your skillset.
                </div>

                <div className="row tech-list justify-content-center align-items-center">
                    <div className="icon-item col-4 col-md-3 col-lg-2 mb-3 mb-lg-5">
                        <img className="rounded" src='/images/tech-icons/javascript.png' alt="tech-image1" />
                    </div>

                    <div className="icon-item col-4 col-md-3 col-lg-2 mb-3 mb-lg-5">
                        <img className="rounded" src='/images/tech-icons/nodejs.png' alt="tech-image-2" />
                    </div>

                    <div className="icon-item col-4 col-md-3 col-lg-2 mb-3 mb-lg-5">
                        <img className="rounded" src='/images/tech-icons/typescript.png' alt="tech-image-3" />
                    </div>

                    <div className="icon-item col-4 col-md-3 col-lg-2 mb-3 mb-lg-5">
                        <img className="rounded" src='/images/tech-icons/react.png' alt="tech-image-4" />
                    </div>

                    <div className="icon-item col-4 col-md-3 col-lg-2 mb-3 mb-lg-5">
                        <img className="rounded" src='/images/tech-icons/angular.png' alt="tech-image-5" />
                    </div>

                    <div className="icon-item col-4 col-md-3 col-lg-2 mb-3 mb-lg-5">
                        <img className="rounded" src='/images/tech-icons/bootstrap5.png' alt="tech-image-6" />
                    </div>

                    <div className="clearfix"></div>

                    <div className="icon-item col-4 col-md-3 col-lg-2 mb-3 mb-lg-5">
                        <img className="rounded" src='/images/tech-icons/python.png' alt="tech-image-7" />
                    </div>

                    <div className="icon-item col-4 col-md-3 col-lg-2 mb-3 mb-lg-5">
                        <img className="rounded" src='/images/tech-icons/django-alt.png' alt="tech-image-8" />
                    </div>

                    <div className="icon-item col-4 col-md-3 col-lg-2 mb-3 mb-lg-5">
                        <img className="rounded" src='/images/tech-icons/solidity.png' alt="tech-image-9" />
                    </div>

                    <div className="icon-item col-4 col-md-3 col-lg-2 mb-3 mb-lg-5">
                        <img className="rounded" src='/images/tech-icons/golang.png' alt="tech-image-10" />
                    </div>

                    <div className="icon-item col-4 col-md-3 col-lg-2 mb-3 mb-lg-5">
                        <img className="rounded" src='/images/tech-icons/c%20%20.png' alt="tech-image-11" />
                    </div>

                    <div className="icon-item col-4 col-md-3 col-lg-2 mb-3 mb-lg-5">
                        <img className="rounded" src='/images/tech-icons/php.png' alt="tech-image-12" />
                    </div>

                    <div className="icon-item col-4 col-md-3 col-lg-2 mb-3 mb-lg-5">
                        <img className="rounded" src='/images/tech-icons/postgresql.png' alt="tech-image-14" />
                    </div>

                    <div className="icon-item col-4 col-md-3 col-lg-2 mb-3 mb-lg-5">
                        <img className="rounded" src='/images/tech-icons/redis.png' alt="tech-image-15" />
                    </div>

                    <div className="icon-item col-4 col-md-3 col-lg-2 mb-3 mb-lg-5">
                        <img className="rounded" src='/images/tech-icons/mongodb.png' alt="tech-image-16" />
                    </div>
                </div>

                <div className="section-cta text-center mt-4">
                    <Link className="btn btn-primary theme-btn-cta" to="/resume">View Resume
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" className="bi bi-arrow-right-short" viewBox="0 0 16 16">
                            <path fillRule="evenodd"
                                d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8z">
                            </path>
                        </svg>
                    </Link>
                </div>

                <div className="skill-blocks py-5 row justify-content-center">
                    <div className="item col-md-6 col-lg-4 text-md-center">
                        <div className="item-icon-holder mx-md-auto">
                            <i className="item-icon bi bi-terminal"></i>
                        </div>

                        <h3 className="item-title mb-3">Web Development</h3>

                        <div className="item-desc">
                            Describe your expertise here. You can change the icon above to any of the <a
                                href="/#" targert="_blank">free Bootstrap icons</a>, consectetur
                            adipiscing elit. Phasellus enim quam, gravida accumsan dignissim eu.
                        </div>
                    </div>

                    <div className="item col-md-6 col-lg-4 text-md-center">
                        <div className="item-icon-holder  mx-md-auto">
                            <i className="item-icon bi bi-database-check"></i>
                        </div>

                        <h3 className="item-title mb-3">Database Infrastructure</h3>
                        <div className="item-desc">
                            Describe your expertise here. You can change the icon above to any of the free Bootstrap
                            icons, consectetur adipiscing elit. Phasellus enim quam, gravida accumsan dignissim eu.
                        </div>
                    </div>

                    <div className="item col-md-6 col-lg-4 text-md-center">
                        <div className="item-icon-holder mx-md-auto">
                            <i className="item-icon bi bi-infinity"></i>
                        </div>

                        <h3 className="item-title mb-3">DevOps</h3>
                        <div className="item-desc">
                            Describe your expertise here. You can change the icon above to any of the free Bootstrap
                            icons, consectetur adipiscing elit. Phasellus enim quam, gravida accumsan dignissim eu.
                        </div>
                    </div>

                    <div className="item col-md-6 col-lg-4 text-md-center">
                        <div className="item-icon-holder mx-md-auto">
                            <i className="item-icon bi bi-apple"></i>
                        </div>

                        <h3 className="item-title mb-3">iOS App <br />Development</h3>
                        <div className="item-desc">
                            Describe your expertise here. You can change the icon above to any of the free Bootstrap
                            icons, consectetur adipiscing elit. Phasellus enim quam, gravida accumsan dignissim eu.
                        </div>
                    </div>

                    <div className="item col-md-6 col-lg-4 text-md-center">
                        <div className="item-icon-holder mx-md-auto">
                            <i className="item-icon bi bi-android2"></i>
                        </div>

                        <h3 className="item-title mb-3">Android App <br />Development</h3>
                        <div className="item-desc">
                            Describe your expertise here. You can change the icon above to any of the free Bootstrap
                            icons, consectetur adipiscing elit. Phasellus enim quam, gravida accumsan dignissim eu.
                        </div>
                    </div>
                </div>

                <div className="section-cta text-center">
                    <Link className="btn btn-primary theme-btn-cta" to="/contact">Get In Touch
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" className="bi bi-arrow-right-short" viewBox="0 0 16 16">
                            <path fillRule="evenodd"
                                d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8z">
                            </path>
                        </svg>
                    </Link>
                </div>
            </section>
        </>
    );
}

export default Skills;