import React from 'react';
import Icons from './Icons';
// import { Link } from 'react-router-dom';


function SocialsLink(props) {
    //console.log(props);
    return (
        <>
            <li className="list-inline-item me-md-1 me-lg-2">
                <a className="cursor-pointer" href="/#">
                    {/* <FontAwesomeIcon icon={faTwitter} /> */}
                    <Icons icon = {{class : "fab fa-twitter"}}/>
                </a>
            </li>

            <li className="list-inline-item me-md-1 me-lg-2">
                <a className="cursor-pointer" href="/#">
                    {/* <FontAwesomeIcon icon={faGitlab} /> */}
                    <Icons icon = {{class : "fab fa-gitlab"}}/>
                </a>
            </li>

            <li className="list-inline-item me-md-1 me-lg-2">
                <a className="cursor-pointer" href="/#">
                    {/* <FontAwesomeIcon icon={faLinkedin} /> */}
                    <Icons icon = {{class : "fab fa-linkedin"}}/>
                </a>
            </li>

            <li className="list-inline-item me-md-1 me-lg-2">
                <a className="cursor-pointer" href="/#">
                    {/* <FontAwesomeIcon icon={faStackOverflow} /> */}
                    <Icons icon = {{class : "fab fa-stack-overflow"}}/>
                </a>
            </li>

            <li className="list-inline-item me-md-1 me-lg-2">
                <a className="cursor-pointer" href="/#">
                    {/* <FontAwesomeIcon icon={faMedium} /> */}
                    <Icons icon = {{class : "fab fa-medium"}}/>
                </a>
            </li>
        </>
    );
}

export default SocialsLink;
