import React, { useEffect } from 'react';
// import { Link } from 'react-router-dom';
import { NavLink } from 'react-router-dom';
import Icons from './Icons';

//import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

// import { faBriefcase } from '@fortawesome/free-solid-svg-icons'
// import { faCircleUser } from '@fortawesome/free-solid-svg-icons'
// import { faPenFancy } from '@fortawesome/free-solid-svg-icons'
// import { faCommentDots } from '@fortawesome/free-solid-svg-icons'
// import { faFileLines } from '@fortawesome/free-solid-svg-icons'

function SidePanel() {
    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);
    

    return (
        <>
            <div className="side-panel position-relative">
                <nav className="navbar">
                    <div aria-labelledby="offcanvasNavbarLabel" className="offcanvas offcanvas-start show d-flex"
                        id="offcanvasNavbar" data-bs-scroll="true" data-bs-backdrop="false" tabIndex="-1">
                        <div className="offcanvas-body d-flex align-items-center">
                            <ul className="main-nav navbar-nav text-center">
                                <li className="nav-item">
                                    <NavLink className="nav-link d-flex flex-column" to="/" activeclassname="active">
                                        <span className="icon-holder">
                                            <Icons icon={{ class: "fas fa-circle-user" }} />
                                        </span>
                                        <span className="nav-text">About</span>
                                    </NavLink>
                                </li>

                                <li className="nav-item">
                                    <NavLink className="nav-link d-flex flex-column" to="/projects" activeclassname="active">
                                        <span className="icon-holder">
                                            {/* <FontAwesomeIcon icon={faBriefcase} /> */}
                                            <Icons icon={{ class: "fas fa-briefcase" }} />
                                        </span>
                                        <span className="nav-text">Projects</span>
                                    </NavLink>
                                </li>

                                <li className="nav-item">
                                    <NavLink className="nav-link d-flex flex-column" to="/blogs" activeclassname="active">
                                        <span className="icon-holder">
                                            {/* <FontAwesomeIcon icon={faPenFancy} /> */}
                                            <Icons icon={{ class: "fas fa-pen-fancy" }} />
                                        </span>
                                        <span className="nav-text">Blogs</span>
                                    </NavLink>
                                </li>

                                <li className="nav-item">
                                    <NavLink className="nav-link d-flex flex-column" to="/resume" activeclassname="active">
                                        <span className="icon-holder">
                                            {/* <FontAwesomeIcon icon={faFileLines} /> */}
                                            <Icons icon={{ class: "fas fa-file-lines" }} />
                                        </span>
                                        <span className="nav-text">Resume</span>
                                    </NavLink>
                                </li>

                                <li className="nav-item">
                                    <NavLink className="nav-link d-flex flex-column" to="/contact" activeclassname="active">
                                        <span className="icon-holder">
                                            {/* <FontAwesomeIcon icon={faCommentDots} /> */}
                                            <Icons icon={{ class: "fas fa-comment-dots" }} />
                                        </span>
                                        <span className="nav-text">Contact</span>
                                    </NavLink>
                                </li>

                                <li className="nav-item nav-item-close">
                                    <button id="offCanvasCloseBtn" className="btn-close d-none d-lg-block" data-bs-toggle="offcanvas" href="#offcanvasNavbar" role="button"
                                        aria-controls="offcanvasNavbar">
                                        <i className="bi bi-x"></i>
                                    </button>
                                </li>
                            </ul>
                        </div>
                    </div>
                </nav>
            </div>
        </>
    );
}

export default SidePanel;
