import React from 'react';
// import { Link } from 'react-router-dom';

function Clients() {
    return (
        <>
            <div className="client-logo-list row d-flex justify-content-center align-items-stretch py-4 gx-2 gx-lg-3">
                <div className="logo-item col-4 col-md-3 col-lg-auto mb-3">
                    <div className="logo-holder">
                        <img className="logo-image" src="/images/clients/client-logo-1.png" alt="client-image" />
                    </div>
                </div>

                <div className="logo-item col-4 col-md-3 col-lg-auto mb-3">
                    <div className="logo-holder">
                        <img className="logo-image" src="/images/clients/client-logo-2.png" alt="client-image" />
                    </div>
                </div>

                <div className="logo-item col-4 col-md-3 col-lg-auto mb-3">
                    <div className="logo-holder">
                        <img className="logo-image" src="/images/clients/client-logo-3.png" alt="client-image" />
                    </div>
                </div>

                <div className="logo-item col-4 col-md-3 col-lg-auto mb-3">
                    <div className="logo-holder">
                        <img className="logo-image" src="/images/clients/client-logo-4.png" alt="client-image" />
                    </div>
                </div>

                <div className="logo-item col-4 col-md-3 col-lg-auto mb-3">
                    <div className="logo-holder">
                        <img className="logo-image" src="/images/clients/client-logo-5.png" alt="client-image" />
                    </div>
                </div>

                <div className="logo-item col-4 col-md-3 col-lg-auto mb-3">
                    <div className="logo-holder">
                        <img className="logo-image" src="/images/clients/client-logo-6.png" alt="client-image" />
                    </div>
                </div>

                <div className="logo-item col-4 col-md-3 col-lg-auto mb-3">
                    <div className="logo-holder">
                        <img className="logo-image" src="/images/clients/client-logo-7.png" alt="client-image" />
                    </div>
                </div>

                <div className="logo-item col-4 col-md-3 col-lg-auto mb-3">
                    <div className="logo-holder">
                        <img className="logo-image" src="/images/clients/client-logo-8.png" alt="client-image" />
                    </div>
                </div>

                <div className="logo-item col-4 col-md-3 col-lg-auto mb-3">
                    <div className="logo-holder">
                        <img className="logo-image" src="/images/clients/client-logo-9.png" alt="client-image" />
                    </div>
                </div>

                <div className="logo-item col-4 col-md-3 col-lg-auto mb-3">
                    <div className="logo-holder">
                        <img className="logo-image" src="/images/clients/client-logo-10.png" alt="client-image" />
                    </div>
                </div>
            </div>
        </>
    );
}

export default Clients;