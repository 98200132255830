import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';

import { Helmet, HelmetProvider } from 'react-helmet-async';

import PageIntro from '../components/PageIntro';
//import Backlinks from './../components/Backlinks';

function Projects() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const pageIntro = {
    page: 'projects',
    img: '/images/profile.png',
    title: 'My Projects',
    titleClass: null,

    description: `Intro about projects goes here. Need help with your project? Book a free session with me to discuss your specific project requirements and how I can help you. `,

    descriptionUrlText: null,
    descriptionUrl: null,
  }

  const backlinks = { title: 'Start A Conversation', url: '/contact', }

  //const backlinks2 = { title: 'Get In Touch', url: '/contact', }

  return <>
    <HelmetProvider>
      <div>
        <Helmet>
          <title>Projects :: Web Artisan</title>
          <meta
            name="description"
            content="projects"
          />
        </Helmet>
      </div>
    </HelmetProvider>

    <PageIntro pageIntro={pageIntro} backlinks={backlinks} />

    <section className="projects-section section mx-auto">
      <div className="row justify-content-center gx-lg-5">
        <div className="project-item col-12 col-md-6 col-lg-12 mb-5">
          <div className="project-item-inner row">
            <div className="col-12 col-lg-5 col-xl-4">
              <img className="project-thumb rounded-2 mb-3 mb-lg-0 rounded mx-auto" src="/images/projects/project-thumb-1.png" alt="project" />
            </div>

            <div className="col-12 col-lg-7 col-xl-8">
              <h3 className="title"><Link to="/project-details">Trading App Lorem</Link></h3>
              <div className="intro mb-3">Project summary goes here. Lorem ipsum dolor sit amet, consectetur adipiscing elit. In metus nunc, fringilla ut viverra ut, placerat sed nisl. Ut eu nisi ut arcu volutpat vehicula. </div>

              <h4 className="subtitle">Tech Stack:</h4>
              <ul className="tech-stack list-inline">
                <li className="list-inline-item"><img className="rounded" src="/images/tech-icons/javascript.png" alt="tech icon1" /></li>
                <li className="list-inline-item"><img className="rounded" src="/images/tech-icons/react.png" alt="tech icon2" /></li>
                <li className="list-inline-item"><img className="rounded" src="/images/tech-icons/bootstrap5.png" alt="tech icon3" /></li>
              </ul>

              <Link className="more-link" to="/project-details">View Details
                <span className="link-arrow"><i className="bi bi-arrow-right"></i></span>
              </Link>

            </div>
          </div>
        </div>

        <div className="project-item col-12 col-md-6 col-lg-12 mb-5">
          <div className="project-item-inner row">
            <div className="col-12 col-lg-5 col-xl-4">
              <img className="project-thumb rounded-2 mb-3 mb-lg-0 rounded mx-auto" src="/images/projects/project-thumb-11.png" alt="project2" />
            </div>

            <div className="col-12 col-lg-7 col-xl-8">
              <h3 className="title"><Link to="/project-details">Smart TV App</Link></h3>
              <div className="intro mb-3">Project summary goes here. Lorem ipsum dolor sit amet, Ut eu nisi ut arcu volutpat vehicula. </div>

              <h4 className="subtitle">Tech Stack:</h4>

              <ul className="tech-stack list-inline">
                <li className="list-inline-item"><img className="rounded" src="/images/tech-icons/java.png" alt="tech icon1" /></li>
                <li className="list-inline-item"><img className="rounded" src="/images/tech-icons/javascript.png" alt="tech icon2" /></li>
                <li className="list-inline-item"><img className="rounded" src="/images/tech-icons/python.png" alt="tech icon3" /></li>
                <li className="list-inline-item"><img className="rounded" src="/images/tech-icons/swift.png" alt="tech icon4" /></li>
                <li className="list-inline-item"><img className="rounded" src="/images/tech-icons/kotlin.png" alt="tech icon5" /></li>
              </ul>

              <Link className="more-link" to="/project-details">View Details
                <span className="link-arrow"><i className="bi bi-arrow-right"></i></span>
              </Link>

            </div>
          </div>
        </div>

        <div className="project-item col-12 col-md-6 col-lg-12 mb-5">
          <div className="project-item-inner row">
            <div className="col-12 col-lg-5 col-xl-4">
              <img className="project-thumb rounded-2 mb-3 mb-lg-0 rounded mx-auto" src="/images/projects/project-thumb-5.png" alt="project" />
            </div>

            <div className="col-12 col-lg-7 col-xl-8">
              <h3 className="title"><Link to="/project-details">Web App Ipsum</Link></h3>
              <div className="intro mb-3">Project summary goes here. For projects which don't have case studies you can link them to their GitHub pages instead.</div>

              <h4 className="subtitle">Tech Stack:</h4>
              <ul className="tech-stack list-inline">
                <li className="list-inline-item"><img className="rounded" src="/images/tech-icons/nodejs.png" alt="tech icon1" /></li>
                <li className="list-inline-item"><img src="/images/tech-icons/html5.png" alt="tech icon2" /></li>
                <li className="list-inline-item"><img src="/images/tech-icons/css3.png" alt="tech icon3" /></li>
              </ul>

              <Link className="more-link" href="/#">View On Github
                <span className="link-arrow"><i className="bi bi-arrow-right"></i></span>
              </Link>

            </div>
          </div>
        </div>

        <div className="project-item col-12 col-md-6 col-lg-12 mb-5">
          <div className="project-item-inner row">
            <div className="col-12 col-lg-5 col-xl-4">
              <img className="project-thumb rounded-2 mb-3 mb-lg-0 rounded mx-auto" src="/images/projects/project-thumb-6.png" alt="project" />
            </div>

            <div className="col-12 col-lg-7 col-xl-8">
              <h3 className="title"><Link to="/project-details">Chrome Extension Maecenas</Link></h3>
              <div className="intro mb-3">Project summary goes here. Sed vel vestibulum dolor. Nam nisl ipsum, tristique ut aliquam vel, dignissim eget dui. Praesent quis imperdiet ex.</div>

              <h4 className="subtitle">Tech Stack:</h4>
              <ul className="tech-stack list-inline">
                <li className="list-inline-item"><img className="rounded" src="/images/tech-icons/typescript.png" alt="tech icon" /></li>
                <li className="list-inline-item"><img className="rounded" src="/images/tech-icons/solidity.png" alt="tech icon" /></li>
              </ul>

              <Link className="more-link" to="/project-details">View Details
                <span className="link-arrow"><i className="bi bi-arrow-right"></i></span>
              </Link>

            </div>
          </div>
        </div>

        <div className="project-item col-12 col-md-6 col-lg-12 mb-5">
          <div className="project-item-inner row">
            <div className="col-12 col-lg-5 col-xl-4">
              <img className="project-thumb rounded-2 mb-3 mb-lg-0 rounded mx-auto" src="/images/projects/project-thumb-4.png" alt="project" />
            </div>

            <div className="col-12 col-lg-7 col-xl-8">
              <h3 className="title"><Link to="/project-details">Data Analytics App</Link></h3>
              <div className="intro mb-3">Project summary goes here. Lorem ipsum dolor sit amet, consectetur adipiscing elit. In metus nunc, fringilla ut viverra ut, placerat sed nisl. </div>

              <h4 className="subtitle">Tech Stack:</h4>
              <ul className="tech-stack list-inline">
                <li className="list-inline-item"><img className="rounded" src="/images/tech-icons/react.png" alt="tech icon" /></li>
                <li className="list-inline-item"><img className="rounded" src="/images/tech-icons/python.png" alt="tech icon" /></li>
                <li className="list-inline-item"><img className="rounded" src="/images/tech-icons/html5.png" alt="tech icon" /></li>
              </ul>

              <Link className="more-link" to="/project-details">View Details
                <span className="link-arrow"><i className="bi bi-arrow-right"></i></span>
              </Link>

            </div>
          </div>
        </div>
      </div>

      <div className="section-cta text-center d-grid col-6 mx-auto">
        <a href="/#" className="btn btn-primary">Load More <span className="d-none d-lg-inline">projects</span></a>
      </div>
    </section>

    <section className="leadgen-section section mx-auto">
      <h3 className="section-heading text-start text-md-center text-highlight"> Need Help With Your Project?</h3>
      <div className="intro text-md-center">Lead generation intro goes here lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse placerat egestas dui, quis ullamcorper massa pellentesque quis. </div>

      {/* <Backlinks backlinks={backlinks2}/> */}

      <div className="text-center mt-4">
        <Link className="btn btn-primary" to="/contact">Get In Touch
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" className="bi bi-arrow-right-short" viewBox="0 0 16 16">
            <path fillRule="evenodd" d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8z"></path>
          </svg>
        </Link>
      </div>
    </section>

  </>;
}

export default Projects;
