import React from 'react';
import { Link } from 'react-router-dom';

function FeaturedProjects() {
    return (
        <>
            <section className="featured-projects-section section mx-auto">
                <h3 className="section-heading mb-4">Featured Projects</h3>

                <div className="section-intro mb-5 limit-max-width mx-auto text-md-center">
                    Brief intro goes here. My
                    main experience lies in building lorem ipsum dolor sit amet, consectetur adipiscing elit. I'm
                    currently working on lacinia tristique.
                </div>

                <div className="featured-project-list container">
                    <div className="row justify-content-center gy-4">
                        <div className="col-12 col-md-6 col-lg-4">
                            <div className="card">
                                <img src="/images/projects/project-thumb-1.png" className="card-img-top" alt="feature-image" />

                                <div className="card-body px-0">
                                    <h5 className="card-title"><a href="/#">Trading App</a></h5>
                                    <p className="card-text mb-1">Project summary goes here. Lorem ipsum dolor sit amet,
                                        consectetur adipiscing elit.</p>
                                    <div><a className="card-link more-link" href="/#">View more <span
                                        className="link-arrow"><i className="bi bi-arrow-right"></i></span></a>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-12 col-md-6 col-lg-4">
                            <div className="card">
                                <img src="/images/projects/project-thumb-6.png" className="card-img-top" alt="project-image" />

                                <div className="card-body px-0">
                                    <h5 className="card-title"><a href="/#">Browser Extension</a></h5>
                                    <p className="card-text mb-1">Project summary goes here. Cras vehicula felis sed eros lacinia rhoncus.</p>

                                    <div>
                                        <a className="card-link more-link" href="/#">View more
                                            <span className="link-arrow">
                                                <i className="bi bi-arrow-right"></i>
                                            </span>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-12 col-md-6 col-lg-4">
                            <div className="card">
                                <img src="/images/projects/project-thumb-4.png" className="card-img-top" alt="project-image" />

                                <div className="card-body px-0">
                                    <h5 className="card-title"><a href="/#">Data Analytics App</a></h5>
                                    <p className="card-text mb-1">Project summary goes here. Cras vehicula felis sed eros lacinia rhoncus.</p>
                                    <div><a className="card-link more-link" href="/#">View more <span
                                        className="link-arrow"><i className="bi bi-arrow-right"></i></span></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="section-cta text-center mt-3">
                        <Link className="btn btn-primary theme-btn-cta" to="/projects">More Projects
            
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" className="bi bi-arrow-right-short" viewBox="0 0 16 16">
                                <path fillRule="evenodd"
                                    d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8z">
                                </path>
                            </svg>
                        </Link>
                    </div>
                </div>
            </section>
        </>
    );
}

export default FeaturedProjects;