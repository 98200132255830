import React from 'react';
import { Link } from 'react-router-dom';
import Backlinks from './Backlinks';

function PageIntro(props) {
    //console.log(props.backlinks);
    const backlinks = props.backlinks;
    return (
        <>
            <section className="page-intro-section section has-profile-image mx-auto">
                {
                    props.pageIntro.img != null ?
                        <div className="profile-holder text-center">
                            <img className="profile-pic rounded-circle" src={props.pageIntro.img} alt={props.pageIntro.title} />
                        </div>
                        : ''
                }

                <div className="intro-holder">
                    <h2 className={"intro-name text-center mb-3 " + (props.pageIntro.titleClass)}>{props.pageIntro.title}</h2>
                    <div className="profile-intro text-md-center limit-max-width mx-auto">
                        <p>{props.pageIntro.description} <Link to={props.pageIntro.descriptionUrl}>{props.pageIntro.descriptionUrlText}</Link>.</p>
                    </div>
                </div>

                <Backlinks  {...{backlinks}}/>

                {
                    // props.pageIntro.url != null ?
                    //     <div className="section-cta text-center mt-4">
                    //         <Link className="btn btn-primary theme-btn-cta" to={props.pageIntro.url}>{props.pageIntro.urlTitle}
                    //             <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" className="bi bi-arrow-right-short" viewBox="0 0 16 16">
                    //                 <path fillRule="evenodd"
                    //                     d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8z">
                    //                 </path>
                    //             </svg>
                    //         </Link>
                    //     </div>
                    // : ''
                }
            </section>
        </>
    );
}

export default PageIntro;
