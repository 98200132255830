import React from 'react';
import ReactDOM from 'react-dom/client';
//import reportWebVitals from './reportWebVitals';

import './assets/npm/bootstrap-icons/font/bootstrap-icons.css';

import "jquery/dist/jquery.min.js";
import 'bootstrap/dist/js/bootstrap.bundle'

import './assets/css/theme-5.css';
import './assets/css/custom.css';
import './App.css';
import './index.css';

import App from './App';

// import { BrowserRouter } from "react-router-dom";

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

//reportWebVitals();
