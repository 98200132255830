import React from 'react';
// import { Link } from 'react-router-dom';

function Footer() {
    return (
        <>
        <footer className="footer text-center py-5">
            <small className="copyright">Copyright &copy; <a className='cursor-pointer' href="/#"> Web Artisan</a></small>
        </footer>
        </>
    );
}

export default Footer;