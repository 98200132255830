import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { faPenNib } from '@fortawesome/free-solid-svg-icons'
import { faEnvelope } from '@fortawesome/free-solid-svg-icons'
import { faBriefcase } from '@fortawesome/free-solid-svg-icons'
import { faCircleUser } from '@fortawesome/free-solid-svg-icons'
import { faPenFancy } from '@fortawesome/free-solid-svg-icons'
import { faCommentDots } from '@fortawesome/free-solid-svg-icons'
import { faFileLines } from '@fortawesome/free-solid-svg-icons'

import { faChevronLeft } from '@fortawesome/free-solid-svg-icons'
import { faChevronRight } from '@fortawesome/free-solid-svg-icons'
import { faQuoteLeft } from '@fortawesome/free-solid-svg-icons'
import { faQuoteRight } from '@fortawesome/free-solid-svg-icons'

import { faTwitter } from '@fortawesome/free-brands-svg-icons'
import { faLinkedin } from '@fortawesome/free-brands-svg-icons'
import { faMedium } from '@fortawesome/free-brands-svg-icons'
import { faStackOverflow } from '@fortawesome/free-brands-svg-icons'
import { faGitlab } from '@fortawesome/free-brands-svg-icons'


const importedIcons = [faPenNib, faEnvelope, faBriefcase, faCircleUser, faPenFancy, faCommentDots, faFileLines,
  faTwitter, faLinkedin, faMedium, faStackOverflow, faGitlab, faChevronLeft, faChevronRight, faQuoteLeft, faQuoteRight
];

const Icons = (props) => {
  //console.log(props.icon);
  //console.log(importedIcons);
  let iconClass = props.icon.class;
  let iconPrefix = null;
  let iconName = null;
  let finalIcon = null;

  if (iconClass) {
    iconPrefix = iconClass.split(' ')[0];
    iconName = iconClass.split(' ')[1];
    // iconName = iconName.split(/[- ]+/);
    iconName = iconName.toString().substring(3);
    //console.log(iconName);

    finalIcon = importedIcons.find((element) => (element.prefix === iconPrefix && element.iconName === iconName));
  }

  return (
    <>
      <FontAwesomeIcon icon={finalIcon} />
    </>
  )
}

export default Icons;