import React, { useEffect } from 'react';

import { Helmet, HelmetProvider } from 'react-helmet-async';
import SocialsLink from '../components/SocialsLink';

function Contact() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return <>
    <HelmetProvider>
      <div>
        <Helmet>
          <title>Contact :: Web Artisan</title>
          <meta name="description" content="contact" />
        </Helmet>
      </div>
    </HelmetProvider>

    <section className="page-intro-section section has-profile-image mx-auto">
      <div className="profile-holder text-center">
        <img className="profile-pic rounded-circle" src="/images/profile.png" alt="profile" />
      </div>

      <div className="intro-holder">
        <h2 className="intro-name text-center">Get In Touch</h2>
        <div className="contact-intro text-md-center limit-max-width mx-auto">
          <p>Contact intro goes here. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis placerat sodales massa vitae ornare. Quisque ac ipsum quam. </p>
        </div>

        <ul className="social-list list-inline mx-auto d-block d-lg-none py-3 text-center">
          <SocialsLink />
        </ul>
      </div>

      <div className="contact-form-wrapper pt-3">
        <form id="contact-form" className="contact-form p-md-4 px-lg-5">
          <h4 className="text-center mb-4">Contact Form</h4>

          <div className="row g-3">
            <div className="col-md-6">
              <label className="sr-only" htmlFor="cname">Name</label>
              <input type="text" className="form-control" id="cname" name="name" placeholder="Name" minLength="2" required="" />
            </div>
            <div className="col-md-6">
              <label className="sr-only" htmlFor="cemail">Email</label>
              <input type="email" className="form-control" id="cemail" name="email" placeholder="Email" required="" />
            </div>

            <div className="col-12">
              <label className="sr-only" htmlFor="cmessage">Your message</label>
              <textarea className="form-control" id="cmessage" name="message" placeholder="Enter your message" rows="10" required=""></textarea>
            </div>
            <div className="col-12">
              <button type="button" className="btn w-100 btn-primary py-2">Submit</button>
            </div>
          </div>
        </form>
      </div>
    </section>
  </>;
}

export default Contact;
